import React, { useEffect, useState } from 'react';
import { StudentsUI } from './ui';
import { Client } from 'src/model/User';
import { useDispatch, useSelector } from 'react-redux';
import { setLoading, useLoading } from 'src/redux/loading/loadingSlice';
import { api } from 'src/services/api';
import { AuthWrapper } from 'src/features/core/auth/screens/AuthWrapper';

export const StudentsScreen: React.FC = (): JSX.Element => {
  const [students, setStudents] = useState<Client[]>([]);
  const { loading } = useSelector(useLoading);
  const dispatch = useDispatch();

  const getUser = async (): Promise<void> => {
    dispatch(setLoading(true));
    const response = await api.get<Client[]>('/user/students');
    setStudents(response.data);
    console.log(response.data);
    dispatch(setLoading(false));
  };

  useEffect(() => {
    getUser();
  }, []);

  return (
    <AuthWrapper isHomepage={false}>
      {students ? (
        <StudentsUI state={loading} students={students} />
      ) : (
        'Sem alunos'
      )}
    </AuthWrapper>
  );
};
