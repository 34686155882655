import React from 'react';
import { Route } from 'src/navigation/Route';
import { TicketListScreen } from './screens/TicketList';
import { TicketsOrderScreen } from './screens/TicketOrder';
import { TicketsScreens } from './screens/Tickets';

export const TICKETS_ROUTES = {
  tickets: '/ingressos/detalhe/:id',
  orders: '/pedidos',
  inscriptions: '/inscricoes',
};

export const TicketsNavigation = () => {
  return (
    <React.Fragment>
      <Route
        path={TICKETS_ROUTES.tickets}
        exact
        component={TicketsScreens}
        isPrivateRoute={true}
      />
      <Route
        path={TICKETS_ROUTES.orders}
        exact
        component={TicketsOrderScreen}
        isPrivateRoute={true}
      />
      <Route
        path={TICKETS_ROUTES.inscriptions}
        exact
        component={TicketListScreen}
        isPrivateRoute={true}
      />
    </React.Fragment>
  );
};
