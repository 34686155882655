import React from 'react';
import { Route } from '../../navigation/Route';
import { CreateTrainingScreen } from './screen/CreateTraining';
import { StudentsScreen } from './screen/Students';
import { CreateClassScreen } from './screen/CreateClass';
import { TrainingsScreen } from './screen/Trainings';
import { TrainingScreen } from './screen/Training';

export const TRAINING_ROUTES = {
  createTraining: '/criar-treino',
  trainings: '/treinos',
  training: '/treino/:id',
  createClass: '/criar-aula/:id',
  students: '/alunos',
};

export const TrainingNavigation = (): JSX.Element => (
  <React.Fragment>
    <Route
      path={TRAINING_ROUTES.createTraining}
      exact
      component={CreateTrainingScreen}
      isPrivateRoute={true}
    />
    <Route
      path={TRAINING_ROUTES.students}
      exact
      component={StudentsScreen}
      isPrivateRoute={true}
    />
    <Route
      path={TRAINING_ROUTES.createClass}
      exact
      component={CreateClassScreen}
      isPrivateRoute={true}
    />
    <Route
      path={TRAINING_ROUTES.training}
      exact
      component={TrainingsScreen}
      isPrivateRoute={true}
    />
    <Route
      path={TRAINING_ROUTES.trainings}
      exact
      component={TrainingScreen}
      isPrivateRoute={true}
    />
  </React.Fragment>
);
