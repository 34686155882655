import React, { FormEvent, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import { AuthWrapper } from 'src/features/core/auth/screens/AuthWrapper';
import validators from 'src/helpers/validators';
import useForm from 'src/hooks/useForm';
import { TrainingData } from 'src/model/TrainingData';
import { setLoading, useLoading } from 'src/redux/loading/loadingSlice';
import { api } from 'src/services/api';
import { FormRegisterClass, RegisterClass } from '../../types';
import { TrainingsUI } from './ui';

interface Params {
  id: string;
}

export const TrainingsScreen: React.FC = (): JSX.Element => {
  const [training, setTraining] = useState<TrainingData[]>([]);
  const { loading } = useSelector(useLoading);
  const dispatch = useDispatch();
  const { id } = useParams<Params>();

  const getTraining = async (): Promise<void> => {
    try {
      dispatch(setLoading(true));
      const reponse = await api.get<TrainingData[]>(`/training/trainings`);
      console.log('Event data:', reponse);
      setTraining(reponse.data);
    } catch (error) {
      console.error('Error fetching event:', error);
    } finally {
      dispatch(setLoading(false));
    }
  };

  const {
    formData: formDataRegister,
    formErrors: formErrorRegister,
    setErrors: setErrorRegister,
    onChangeFormInput: onChangeFormInputRegister,
    isFormValid: isFormValidRegister,
    resetForm: resetFormRegister,
  } = useForm({
    initialData: {
      day: '',
      hour: '',
    },
    validators: {
      day: [validators.required],
      hour: [validators.required],
    },
  });

  const handleOnSubmitRegister = async (e: FormEvent): Promise<void> => {
    try {
      e.preventDefault();
      if (isFormValidRegister()) {
        dispatch(setLoading(true));
        const payload: RegisterClass = {
          training: id,
          day: formDataRegister[FormRegisterClass.day],
          hour: formDataRegister[FormRegisterClass.hour],
        };
        console.log(payload);
        await api.post('/training/class', payload);
        // resetFormRegister();
        toast.success('Dados salvos com sucesso!');
        resetFormRegister();
        getTraining();
      }
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
    } catch (error: any) {
      //   console.error(error);
      let errorMessage =
        'Falha ao realizar o cadastro, tente novamente mais tarde';
      if (error.response && error.response.data) {
        errorMessage =
          typeof error.response.data === 'string'
            ? error.response.data
            : JSON.stringify(error.response.data);
      }
      toast.error(errorMessage);
      setErrorRegister({
        className: [errorMessage],
      });
    } finally {
      dispatch(setLoading(false));
    }
  };

  useEffect(() => {
    getTraining();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <AuthWrapper isHomepage={false}>
      {training ? (
        <TrainingsUI
          data={training}
          state={loading}
          formData={formDataRegister}
          formErrors={formErrorRegister}
          onSubmitRegister={handleOnSubmitRegister}
          onChangeFormInput={onChangeFormInputRegister}
        />
      ) : (
        <p>Carregando Treinos...</p>
      )}
    </AuthWrapper>
  );
};
