import React, { FormEvent } from 'react';
import { AuthWrapper } from 'src/features/core/auth/screens/AuthWrapper';
import { CreateTrainingUI } from './ui';
import { useDispatch, useSelector } from 'react-redux';
import cep from 'cep-promise';
import { ClassBasic, FormRegisterTraining } from '../../types';
import useForm from 'src/hooks/useForm';
import validators from 'src/helpers/validators';
import { updateMask as updateMaskCEP } from 'src/helpers/masks/cep';
import { toast } from 'react-toastify';
import { setLoading, useLoading } from 'src/redux/loading/loadingSlice';
import { Address } from 'src/model/Address';
import { api } from 'src/services/api';
import { Training } from 'src/model/Training';
import { useHistory } from 'react-router-dom';

export const CreateTrainingScreen: React.FC = (): JSX.Element => {
  const { loading } = useSelector(useLoading);
  const dispatch = useDispatch();
  const history = useHistory();

  const {
    formData: formDataRegister,
    formErrors: formErrorRegister,
    setErrors: setErrorRegister,
    onChangeFormInput: onChangeFormInputRegister,
    isFormValid: isFormValidRegister,
    resetForm: resetFormRegister,
  } = useForm({
    initialData: {
      classType: '',
      className: '',
      zipCode: '',
      state: '',
      city: '',
      district: '',
      street: '',
      complement: '',
      addressnumber: '',
      country: '',
    },
    validators: {
      classType: [validators.required],
      className: [validators.required],
      zipCode: [validators.required],
      state: [validators.required],
      city: [validators.required],
      district: [validators.required],
      street: [validators.required],
      addressnumber: [validators.required],
      country: [validators.required],
    },
    formatters: {
      zipCode: updateMaskCEP,
    },
  });

  const handleOnSubmitRegister = async (e: FormEvent): Promise<void> => {
    try {
      e.preventDefault();
      if (isFormValidRegister()) {
        dispatch(setLoading(true));
        const address = {
          zipcode: formDataRegister[FormRegisterTraining.zipCode],
          state: formDataRegister[FormRegisterTraining.state],
          city: formDataRegister[FormRegisterTraining.city],
          district: formDataRegister[FormRegisterTraining.district],
          street: formDataRegister[FormRegisterTraining.street],
          complement: formDataRegister[FormRegisterTraining.complement],
          number: formDataRegister[FormRegisterTraining.addressnumber],
          country: formDataRegister[FormRegisterTraining.country],
        } as Address;

        const payload: ClassBasic = {
          type: formDataRegister[FormRegisterTraining.classType],
          name: formDataRegister[FormRegisterTraining.className],
          address: address,
        };
        console.log(payload);
        const response = await api.post<Training>('/training', payload);
        console.log(response);
        handleClass(response.data.id);
      }
    } catch (error: any) {
      //   console.error(error);
      let errorMessage =
        'Falha ao realizar o cadastro, tente novamente mais tarde';
      if (error.response && error.response.data) {
        errorMessage =
          typeof error.response.data === 'string'
            ? error.response.data
            : JSON.stringify(error.response.data);
      }
      toast.error(errorMessage);
      setErrorRegister({
        className: [errorMessage],
      });
    } finally {
      dispatch(setLoading(false));
    }
  };

  const handleClass = (id: string): void => {
    console.log(id);
    history.replace(`/treino/${id}`);
  };

  const handleOnChangeCEP = async (value: string): Promise<void> => {
    if (value.length === 9) {
      const cepResponse = await cep(value);
      onChangeFormInputRegister(FormRegisterTraining.state)(cepResponse.state);
      onChangeFormInputRegister(FormRegisterTraining.city)(cepResponse.city);
      onChangeFormInputRegister(FormRegisterTraining.district)(
        cepResponse.neighborhood
      );
      onChangeFormInputRegister(FormRegisterTraining.street)(
        cepResponse.street
      );
    }
  };

  return (
    <AuthWrapper isHomepage={false}>
      <CreateTrainingUI
        state={loading}
        formData={formDataRegister}
        formErrors={formErrorRegister}
        onChangeCEP={handleOnChangeCEP}
        onSubmitRegister={handleOnSubmitRegister}
        onChangeFormInput={onChangeFormInputRegister}
      />
    </AuthWrapper>
  );
};
