import React from 'react';
import { MdAddCircleOutline } from 'react-icons/md';
import { IconMenu } from 'src/assets/icons/IconMenu';
import { IconTicket } from 'src/assets/icons/IconTicket';
import { IconTransfer } from 'src/assets/icons/IconTransfer';
import { DropdownMenu } from 'src/components/DropdownMenu';
import { CustomTable, TableColumn } from 'src/components/Table';
import { TrainingData } from 'src/model/TrainingData';

interface TrainingProps {
  state: boolean;
  trainings: TrainingData[];
  onEdite: (id: string) => void;
  onCancel: (id: string) => void;
  goTo: (href: string) => void;
}

export interface DataRow {
  id: string;
  name: string;
  type: string;
  status: string;
  actions: string;
}

const columns: TableColumn<DataRow>[] = [
  {
    name: 'Nome Treino',
    selector: (row) => row.name,
    width: '300px',
  },
  {
    name: 'Tipo',
    selector: (row) => row.type,
    width: '150px',
  },
  {
    name: 'Status',
    selector: (row) => row.status,
    width: '120px',
  },
  {
    name: 'Ações',
    selector: (row) => row.actions,
    width: '150px',
  },
];

export const TrainingUI: React.FC<TrainingProps> = ({
  state,
  trainings,
  onEdite,
  onCancel,
  goTo,
}) => {
  const handleTypeTraining = (e: string): string => {
    let type = 'Não definido';
    switch (e) {
      case 'CHILDREN':
        type = 'Infantil';
        break;
      case 'YOUTH':
        type = 'Jovem';
        break;
      case 'ADULT':
        type = 'Adulto';
        break;
      default:
        break;
    }

    return type;
  };

  const handleDayOfWeek = (day: string): string => {
    let dayName = 'Dia não definido';
    console.log(day);
    switch (day) {
      case 'MONDAY':
        dayName = 'Segunda-feira';
        break;
      case 'TUESDAY':
        dayName = 'Terça-feira';
        break;
      case 'WEDNESDAY':
        dayName = 'Quarta-feira';
        break;
      case 'THURSDAY':
        dayName = 'Quinta-feira';
        break;
      case 'FRIDAY':
        dayName = 'Sexta-feira';
        break;
      case 'SATURDAY':
        dayName = 'Sábado';
        break;
      case 'SUNDAY':
        dayName = 'Domingo';
        break;
      default:
        break;
    }

    return dayName;
  };

  const dataTable = trainings.map((item) => ({
    id: item.training.id,
    name: item.training.name,
    type: handleTypeTraining(item.training.type.toString()),
    status: (
      <span title={item.training.status.toString()}>
        {item.training.status.toString() === 'ACTIVE'
          ? 'Ativo'
          : item.training.status}
      </span>
    ),
    actions: (
      <div>
        <DropdownMenu
          title={<IconMenu className="h-[24px]" />}
          actions={[
            {
              title: 'Editar',
              onClick: () => onEdite(item.training.id),
              icon: <IconTicket className="h-[19px]" />,
            },
            {
              title: 'Cancelar',
              onClick: () => onCancel(item.training.id),
              icon: <IconTransfer className="h-[22px]" />,
            },
          ]}
        />
      </div>
    ),
  }));
  return (
    <section className="p-4 space-y-[70px] mt-[15px] mb-[100px] max-w-5xl mx-auto ">
      <div className="mt-5 pt-5 max-w-5xl mx-auto border border-gray-100 rounded shadow-outline pb-6">
        <div className="ml-5">
          <h1 className=" flex flex-rowtext-gray-700 text-2xl align-center font-dmsans font-bold mb-10">
            Treinos{' '}
            <a
              href="#"
              className="flex items-center px-4 py-2 "
              role="menuitem"
              tabIndex={-1}
              onClick={() => goTo('/criar-treino')}
            >
              {' '}
              <MdAddCircleOutline className="mr-2" />{' '}
            </a>
          </h1>
        </div>
        <div className="max-w-5xl mx-auto p-4 min-h-[400px]">
          <CustomTable
            columns={columns}
            data={dataTable}
            numberRowsPerPage={2}
            progressPending={state}
          />
        </div>
      </div>
    </section>
  );
};
