import React, { FormEvent } from 'react';
import { Button } from 'src/components/Button';
import { InputText } from 'src/components/InputText';
import { OptionProps, Select } from 'src/components/Select';
import { FormRegisterTraining } from 'src/features/training/types';
import { UseFormReturn } from 'src/hooks';
import { countryOptions } from 'src/components/Country';

export interface CreateTrainingUIProps
  extends Pick<UseFormReturn, 'formData' | 'formErrors' | 'onChangeFormInput'> {
  state: boolean;
  onChangeCEP: (value: string) => void;
  onSubmitRegister: (e: FormEvent) => void;
}

export const CreateTrainingUI: React.FC<CreateTrainingUIProps> = ({
  state,
  formData,
  formErrors,
  onChangeCEP,
  onSubmitRegister,
  onChangeFormInput,
}): JSX.Element => {
  const classType: OptionProps[] = [
    { value: 'CHILDREN', label: 'Infantil' },
    { value: 'YOUTH', label: 'Juvenil' },
    { value: 'ADULT', label: 'Adulto' },
  ];

  const stateOptions: OptionProps[] = [
    { value: 'AC', label: 'Acre' },
    { value: 'AL', label: 'Alagoas' },
    { value: 'AP', label: 'Amapa' },
    { value: 'AM', label: 'Amazonas' },
    { value: 'BA', label: 'Bahia' },
    { value: 'CE', label: 'Ceara' },
    { value: 'DF', label: 'Distrito Federal' },
    { value: 'ES', label: 'Espirito Santo' },
    { value: 'GO', label: 'Goias' },
    { value: 'MA', label: 'Maranhao' },
    { value: 'MT', label: 'Mato Grosso' },
    { value: 'MS', label: 'Mato Grosso do Sul' },
    { value: 'MG', label: 'Minas Gerais' },
    { value: 'PA', label: 'Para' },
    { value: 'PB', label: 'Paraiba' },
    { value: 'PR', label: 'Parana' },
    { value: 'PE', label: 'Pernambuco' },
    { value: 'PI', label: 'Piaui' },
    { value: 'RJ', label: 'Rio de Janeiro' },
    { value: 'RN', label: 'Rio Grande do Norte' },
    { value: 'RS', label: 'Rio Grande do Sul' },
    { value: 'RO', label: 'Rondonia' },
    { value: 'RR', label: 'Roraima' },
    { value: 'SC', label: 'Santa Catarina' },
    { value: 'SP', label: 'Sao Paulo' },
    { value: 'SE', label: 'Sergipe' },
    { value: 'TO', label: 'Tocantins' },
  ];
  return (
    <section className="p-4 space-y-[70px] mt-[15px] mb-[100px] max-w-5xl mx-auto ">
      <form onSubmit={onSubmitRegister}>
        <div className="mt-5 pt-5 max-w-5xl mx-auto border border-gray-100 rounded shadow-outline pb-6">
          <div className="ml-5">
            <h1 className="text-gray-700 text-2xl font-dmsans font-bold mb-10">
              Criar treino
            </h1>
          </div>
          <div className="p-3 max-w-4xl mx-auto">
            <div>
              <Select
                name="classType"
                label="Tipo da Turma"
                placeholder="Selecione..."
                className="w-full mb-5"
                value={formData[FormRegisterTraining.classType]}
                onChange={(e) =>
                  onChangeFormInput(FormRegisterTraining.classType)(
                    e?.target.value as string
                  )
                }
                error={formErrors.classType && formErrors.classType[0]}
                options={classType}
              />
              <InputText
                name="className"
                label="Nome Turma"
                placeholder="Digite aqui..."
                maxLength={100}
                value={formData[FormRegisterTraining.className]}
                onChange={(e) =>
                  onChangeFormInput(FormRegisterTraining.className)(
                    e.target.value
                  )
                }
                error={formErrors.className && formErrors.className[0]}
              />
            </div>
            <div>
              <h1 className="mt-10 mb-2 text-gray-700 text-lg font-dmsans font-bold">
                Endereço:
              </h1>

              <div className="mb-5 grid grid-cols-1 md:grid-cols-2 gap-4">
                <InputText
                  name="zipCode"
                  label="CEP"
                  placeholder="00000-000"
                  className={`appearance-none w-full
                rounded-md text-heading leading-tight focus:outline-none
                focus:shadow-outline text-xs font-dmsans font-normal`}
                  value={formData[FormRegisterTraining.zipCode]}
                  maxLength={9}
                  onChange={(e) => {
                    onChangeFormInput(FormRegisterTraining.zipCode)(
                      e.target.value
                    );
                    onChangeCEP(e.target.value);
                  }}
                  error={formErrors.zipCode && formErrors.zipCode[0]}
                />
                <InputText
                  name="street"
                  label="Rua/Avenida/Conjunto"
                  placeholder="Digite aqui..."
                  maxLength={100}
                  value={formData[FormRegisterTraining.street]}
                  onChange={(e) =>
                    onChangeFormInput(FormRegisterTraining.street)(
                      e?.target.value as string
                    )
                  }
                  error={formErrors.street && formErrors.street[0]}
                />
              </div>
              <div className="mb-5 grid grid-cols-1 md:grid-cols-3 gap-4">
                <InputText
                  name="addressNumber"
                  label="Número"
                  placeholder="Digite aqui..."
                  maxLength={100}
                  value={formData[FormRegisterTraining.addressnumber]}
                  onChange={(e) =>
                    onChangeFormInput(FormRegisterTraining.addressnumber)(
                      e?.target.value as string
                    )
                  }
                  error={
                    formErrors.addressnumber && formErrors.addressnumber[0]
                  }
                />
                <InputText
                  name="complement"
                  label="Complemento (opcional)"
                  placeholder="Digite aqui..."
                  maxLength={100}
                  value={formData[FormRegisterTraining.complement]}
                  onChange={(e) =>
                    onChangeFormInput(FormRegisterTraining.complement)(
                      e?.target.value as string
                    )
                  }
                  error={formErrors.complement && formErrors.complement[0]}
                />
                <InputText
                  name="district"
                  label="Bairro:"
                  placeholder="Digite aqui..."
                  maxLength={100}
                  value={formData[FormRegisterTraining.district]}
                  onChange={(e) =>
                    onChangeFormInput(FormRegisterTraining.district)(
                      e?.target.value as string
                    )
                  }
                  error={formErrors.district && formErrors.district[0]}
                />
                <InputText
                  name="city"
                  label="Cidade:"
                  placeholder="Digite aqui..."
                  maxLength={100}
                  value={formData[FormRegisterTraining.city]}
                  onChange={(e) =>
                    onChangeFormInput(FormRegisterTraining.city)(
                      e?.target.value as string
                    )
                  }
                  error={formErrors.city && formErrors.city[0]}
                />
                <Select
                  name="state"
                  label="Estado"
                  placeholder="Selecione..."
                  value={formData[FormRegisterTraining.state]}
                  onChange={(e) =>
                    onChangeFormInput(FormRegisterTraining.state)(
                      e?.target.value as string
                    )
                  }
                  className="col-span-2"
                  error={formErrors.state && formErrors.state[0]}
                  options={stateOptions}
                ></Select>
                <Select
                  name="country"
                  label="Pais"
                  placeholder="Selecione..."
                  value={formData[FormRegisterTraining.country]}
                  onChange={(e) =>
                    onChangeFormInput(FormRegisterTraining.country)(
                      e?.target.value as string
                    )
                  }
                  className="col-span-2"
                  error={formErrors.country && formErrors.country[0]}
                  options={countryOptions}
                ></Select>
              </div>
            </div>
          </div>
          <div className="p-3 max-w-4xl mx-auto">
            <Button
              type="submit"
              title="Criar a minha treino"
              buttonStyle="primary"
              size="xlg"
              className="w-full"
              disabled={
                formData[FormRegisterTraining.className] === '' ||
                formData[FormRegisterTraining.classType] === '' ||
                formData[FormRegisterTraining.zipCode] === '' ||
                formData[FormRegisterTraining.state] === '' ||
                formData[FormRegisterTraining.city] === '' ||
                formData[FormRegisterTraining.district] === '' ||
                formData[FormRegisterTraining.street] === '' ||
                formData[FormRegisterTraining.addressnumber] === '' ||
                formData[FormRegisterTraining.country] === '' ||
                state
              }
            />
          </div>
        </div>
      </form>
    </section>
  );
};
