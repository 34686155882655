import React, { MouseEventHandler, useState } from 'react';
import { IconCalendar } from 'src/assets/icons';
import { MasonryUI } from 'src/components/Mansory';
import { TrainingForMestre } from 'src/model/TrainingForMestre';

interface Professores {
  professor: string;
  phone: string;
  local: End[];
  foto: string;
}

interface End {
  local: string;
  end: string;
  turma: Turma[];
}

interface Turma {
  horas: string;
  dias: string;
}

interface City {
  state: string;
  aulas: Professores[];
}

interface TrainingSessionProps {
  training: TrainingForMestre[];
}

export const TrainingSession: React.FC<TrainingSessionProps> = ({
  training,
}): JSX.Element => {
  const [state, setState] = useState<string>('DF');

  const handleDayOfWeek = (day: string): string => {
    const days: { [key: string]: string } = {
      MONDAY: 'Segunda-feira',
      TUESDAY: 'Terça-feira',
      WEDNESDAY: 'Quarta-feira',
      THURSDAY: 'Quinta-feira',
      FRIDAY: 'Sexta-feira',
      SATURDAY: 'Sábado',
      SUNDAY: 'Domingo',
    };
    return days[day] || 'Dia não definido';
  };

  const professores: City[] = training.reduce<City[]>((acc, value) => {
    if (value.trainings.length === 0) return acc;

    const existingCity = acc.find(
      (city) => city.state === value.user.address?.state
    );
    const newAula = {
      professor: value.user.name,
      phone: value.user.cellPhone,
      local: value.trainings.map((data) => ({
        local: data.training.address?.district || '',
        end: `${data.training.address?.street || ''} ${
          data.training.address?.number || ''
        }`,
        turma: data.classTraining.map((classTraining) => ({
          horas: classTraining.hour,
          dias: handleDayOfWeek(classTraining.day),
        })),
      })),
      foto: value.user.image || '',
    };

    if (existingCity) {
      existingCity.aulas.push(newAula);
    } else {
      acc.push({
        state: value.user.address?.state || 'Outros',
        aulas: [newAula],
      });
    }

    return acc;
  }, []);

  const handleClick: MouseEventHandler<HTMLDivElement> = (event) => {
    const nome = event.currentTarget.textContent;
    setState(nome || 'DF');
  };

  const handleClun = (estado: string, cidades: City[]): number => {
    const cidadeEncontrada = cidades.find((cidade) => cidade.state === estado);
    return cidadeEncontrada && cidadeEncontrada.aulas.length > 2 ? 3 : 2;
  };

  return (
    <div className="pb-6 mx-auto px-4 sm:px-6 lg:px-8">
      <div className="flex flex-wrap justify-center space-x-4 space-y-4">
        {professores.map((city, index) => (
          <div
            key={`${city.state}-${index}`}
            className="border border-gray-500 rounded p-2 mb-2 cursor-pointer transition duration-300 hover:bg-[#025c09] hover:text-[#effef0] hover:border-gray-600 text-center text-sm sm:text-base"
            onClick={handleClick}
          >
            {city.state}
          </div>
        ))}
      </div>
      <MasonryUI colun={handleClun(state, professores)}>
        {professores
          .filter((professor) => professor.state === state)
          .flatMap((professor) =>
            professor.aulas.map((aulas, index) => (
              <div
                key={`${aulas.professor}-${index}`}
                className="bg-gradient-to-t from-[#e7e7e7] to-[#f1f1f1] p-4 border border-[#a8a8a8] rounded-lg shadow-lg mb-3 transition duration-300 transform hover:scale-105 flex flex-col"
              >
                <div className="flex flex-col md:flex-row items-center space-x-4">
                  <div className="md:w-2/3">
                    <div className="flex items-center mb-2">
                      <IconCalendar className="h-6 text-gray-500" />
                      <p className="ml-2 font-bold text-gray-500">Aulas</p>
                    </div>
                    <p className="text-lg font-bold uppercase text-gray-500">
                      {aulas.professor}
                    </p>
                    <p className="text-base text-gray-500 uppercase mb-2">
                      {aulas.phone}
                    </p>
                  </div>
                  <div className="md:w-1/3 flex justify-center">
                    <img
                      className="rounded-lg border-[#ffffff] border w-24 h-24 object-cover"
                      src={`https://api.ngolocapoeira.org/uploads/${aulas.foto}`}
                      alt="Imagem do Professor"
                    />
                  </div>
                </div>
                <div
                  className={`grid ${
                    aulas.local.length > 1
                      ? 'grid-cols-1 md:grid-cols-2 gap-4'
                      : 'grid-cols-1'
                  } mt-4`}
                >
                  {aulas.local.map((local, idx) => (
                    <div
                      key={`${local.end}-${idx}`}
                      className="bg-[#ececec] border-[#a8a8a8] rounded-lg border p-2"
                    >
                      <p className="text-[#013209] font-bold text-sm">
                        {local.local}
                      </p>
                      <p className="text-[#013209] text-xs">{local.end}</p>
                      {local.turma.map((t, i) => (
                        <div key={`${t.horas}-${i}`}>
                          <p className="text-[#013209] text-xs font-semibold">
                            {t.dias}
                            <span> - {t.horas}</span>
                          </p>
                        </div>
                      ))}
                    </div>
                  ))}
                </div>
              </div>
            ))
          )}
      </MasonryUI>
    </div>
  );
};
