import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { Training } from 'src/model/Training';
import { setLoading, useLoading } from 'src/redux/loading/loadingSlice';
import { api } from 'src/services/api';
import { TrainingUI } from './ui';
import { TrainingData } from 'src/model/TrainingData';
import { AuthWrapper } from 'src/features/core/auth/screens/AuthWrapper';

export const TrainingScreen: React.FC = (): JSX.Element => {
  const { loading } = useSelector(useLoading);
  const dispatch = useDispatch();
  const history = useHistory();
  const [trainings, setTrainings] = useState<TrainingData[]>([]);

  const getTraining = async (): Promise<void> => {
    try {
      dispatch(setLoading(true));
      const { data } = await api.get<TrainingData[]>(`/training/trainings`);
      console.log('Event data:', data);
      setTrainings(data);
    } catch (error) {
      console.error('Error fetching event:', error);
    } finally {
      dispatch(setLoading(false));
    }
  };

  const handleOnEdite = (id: string): void => {
    history.replace(`/treino`);
  };

  const handleOnCancel = (id: string): void => {
    console.log(id);
  };

  const handleOnGoTo = (href: string): void => {
    history.replace(href);
  };

  useEffect(() => {
    getTraining();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <AuthWrapper isHomepage={false}>
      <TrainingUI
        state={loading}
        trainings={trainings}
        onEdite={handleOnEdite}
        onCancel={handleOnCancel}
        goTo={handleOnGoTo}
      />
    </AuthWrapper>
  );
};
