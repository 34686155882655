import React from 'react';
import { CustomTable, TableColumn } from 'src/components/Table';
import { Client } from 'src/model/User';

interface StudentsUIProps {
  state: boolean;
  students: Client[];
}

export interface DataRow {
  id: string;
  name: string;
  nickname: string;
  nameMestre: string;
  corda: string;
}

const columns: TableColumn<DataRow>[] = [
  {
    name: 'Aluno',
    selector: (row) => row.name,
    width: '300px',
  },
  {
    name: 'Apelido',
    selector: (row) => row.nickname,
    width: '200px',
  },
  {
    name: 'Mestre',
    selector: (row) => row.nameMestre,
    width: '200px',
  },
  {
    name: 'Corda',
    selector: (row) => row.corda,
    width: '200px',
  },
];

export const StudentsUI: React.FC<StudentsUIProps> = ({
  state,
  students,
}): JSX.Element => {
  const substituirUnderlinePorEspaco = (texto: string): string => {
    return texto.replace(/_/g, '-');
  };

  const dataTable = students.map((item) => ({
    id: item.id,
    name: item.name,
    nickname: item.nickname,
    nameMestre: item.nameMestre,
    corda: substituirUnderlinePorEspaco(item.corda),
  }));

  return (
    <section className="p-4 space-y-[70px] mt-[15px] mb-[100px] max-w-5xl mx-auto ">
      <div className="mt-5 pt-5 max-w-5xl mx-auto border border-gray-100 rounded shadow-outline pb-6">
        <div className="ml-5">
          <h1 className="text-gray-700 text-2xl font-dmsans font-bold mb-10">
            Alunos
          </h1>
        </div>
        <div className="max-w-5xl mx-auto p-4 min-h-[400px]">
          <CustomTable
            columns={columns}
            data={dataTable}
            numberRowsPerPage={2}
            progressPending={state}
          />
        </div>
      </div>
    </section>
  );
};
