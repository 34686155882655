import React, { FormEvent } from 'react';
import { Button } from 'src/components/Button';
import { InputText } from 'src/components/InputText';
import { OptionProps, Select } from 'src/components/Select';
import { FormRegisterClass } from 'src/features/training/types';
import { UseFormReturn } from 'src/hooks';

export interface CreateClassUIProps
  extends Pick<UseFormReturn, 'formData' | 'formErrors' | 'onChangeFormInput'> {
  state: boolean;
  onSubmitRegister: (e: FormEvent) => void;
}

export const CreateClassUI: React.FC<CreateClassUIProps> = ({
  state,
  formData,
  formErrors,
  onSubmitRegister,
  onChangeFormInput,
}): JSX.Element => {
  const days: OptionProps[] = [
    { value: 'MONDAY', label: 'Segunda' },
    { value: 'TUESDAY', label: 'Terça' },
    { value: 'WEDNESDAY', label: 'Quarta' },
    { value: 'THURSDAY', label: 'Quinta' },
    { value: 'FRIDAY', label: 'Sexta' },
    { value: 'SATURDA', label: 'Sabado' },
    { value: 'SUNDAY', label: 'Domingo' },
  ];

  return (
    <>
      <form onSubmit={onSubmitRegister}>
        <div>
          <Select
            name="classType"
            label="Selecione o Dia"
            placeholder="Selecione..."
            className="w-full mb-5"
            value={formData[FormRegisterClass.day]}
            onChange={(e) =>
              onChangeFormInput(FormRegisterClass.day)(
                e?.target.value as string
              )
            }
            error={formErrors.day && formErrors.day[0]}
            options={days}
          />
          <InputText
            name="className"
            label="Digite o Horário"
            placeholder="Digite aqui..."
            maxLength={100}
            value={formData[FormRegisterClass.hour]}
            onChange={(e) =>
              onChangeFormInput(FormRegisterClass.hour)(e.target.value)
            }
            error={formErrors.hour && formErrors.hour[0]}
          />
        </div>
        <div className="p-3 max-w-4xl mx-auto">
          <Button
            type="submit"
            title="Criar a minha aula"
            buttonStyle="primary"
            size="xlg"
            className="w-full"
            disabled={
              formData[FormRegisterClass.day] === '' ||
              formData[FormRegisterClass.hour] === '' ||
              state
            }
          />
        </div>
      </form>
    </>
  );
};
