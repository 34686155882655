import { Address } from '../../../model/Address';
export enum FormRegisterTraining {
  classType = 'classType',
  className = 'className',
  zipCode = 'zipCode',
  state = 'state',
  city = 'city',
  district = 'district',
  street = 'street',
  complement = 'complement',
  addressnumber = 'addressnumber',
  country = 'country',
}

export interface ClassBasic {
  type: string;
  name: string;
  address: Address;
}

export enum FormRegisterClass {
  day = 'day',
  hour = 'hour',
}

export interface RegisterClass {
  training: string;
  day: string;
  hour: string;
}
