import * as DropdownMenu from '@radix-ui/react-dropdown-menu';
import { useEffect, useRef, useState } from 'react';
import {
  MdAccessibilityNew,
  MdAddCard,
  MdAssignment,
  MdAssignmentInd,
  MdEvent,
  MdPerson,
} from 'react-icons/md';
import { getBoolean, getItem } from 'src/helpers/common/localStorage';
import { AuthUser } from 'src/model/AuthUser';
import { colors } from 'src/styles/colors';
import { REACT_APP_AUTH, REACT_APP_USER } from 'src/utils/config';
import { Bell, CaretDown, Logout, Sizes } from '../../assets/icons';
import { IconHamburger } from '../../assets/icons/IconHamburger';
import logoPreta from '../../assets/images/NGOLO_PRETA.png';
import logo from '../../assets/images/logo_ngolo_branca.png';
import { classNames } from '../../helpers/common';
import { NavigationMenu } from '../../types';
import { Dropdown } from '../Dropdown';
import { Menu } from '../Menu';

interface HeaderProps {
  homepage?: boolean;
  goTo: (href: string) => void;
  handleOnSignIn?: () => void;
  handleOnLogout?: () => void;
}

export const Header: React.FC<HeaderProps> = ({
  homepage,
  goTo,
  handleOnSignIn,
  handleOnLogout,
}) => {
  const signed = getBoolean(String(REACT_APP_AUTH));
  const user: AuthUser = getItem(String(REACT_APP_USER));
  const role = user.type;

  const navigation: NavigationMenu[] = [
    { name: 'Início', href: '/', current: true },
    { name: 'História', href: '/historia', current: false },
    {
      name: 'O Grupo',
      href: '#',
      current: false,
      submenu: [
        { name: 'Mestre Dionizio', href: '/mestre-dionizio', current: false },
        // { name: 'Membro do Grupo', href: '#', current: false },
        { name: 'Sistema de Graduação', href: '/graduacao', current: true },
      ],
    },
    { name: 'Eventos', href: '/eventos', current: false },
    { name: 'Musicas', href: '/musicas', current: false },
    { name: 'Artigos', href: '/articles', current: false },
    // { name: 'Mídia', href: '#', current: false },
    // { name: 'Contato', href: '#', current: false },
  ];

  const [openMenu, setOpenMenu] = useState(false);
  const [dropdownOpen, setDropdownOpen] = useState<string | null>(null);
  const dropdownRef = useRef<HTMLDivElement | null>(null);

  const handleDropdown = (itemName: string) => {
    setDropdownOpen(dropdownOpen === itemName ? null : itemName);
  };

  const getLettersName = (name: string) => {
    const names = name.split(' ');
    let initials = names[0].substring(0, 1).toUpperCase();

    if (names.length > 1) {
      initials += names[names.length - 1].substring(0, 1).toUpperCase();
    }
    return initials;
  };

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (
        dropdownRef.current &&
        !dropdownRef.current.contains(event.target as Node) &&
        !(event.target as HTMLElement).closest('.submenu') &&
        !dropdownRef.current.parentElement?.contains(event.target as Node)
      ) {
        setDropdownOpen(null);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [dropdownRef]);

  return (
    <header
      className={classNames(
        homepage ? 'bg-header absolute top-0 left-0' : 'bg-white',
        'w-full shadow-xs'
      )}
    >
      <div className="container mx-auto h-28 flex justify-between items-center">
        <div className="flex flex-1 items-center sm:items-stretch sm:justify-start">
          <div className="flex flex-shrink-0 items-center">
            <a href="#" onClick={() => goTo('/')}>
              <img
                className="w-auto h-[80px] hidden lg:block"
                src={homepage ? logo : logoPreta}
                alt="N`GOLO"
              />
              <img
                className="block w-auto h-[80px] lg:hidden"
                src={logoPreta}
                alt="NGOLO Capoeira"
              />
            </a>
          </div>
          <div className="hidden sm:ml-6 sm:flex items-center">
            <nav className="mr-auto ml-8 space-x-8 flex">
              {navigation.map((item) => (
                <div key={item.name} className="relative" ref={dropdownRef}>
                  <a
                    href="#"
                    className={classNames(
                      homepage ? 'text-white' : 'text-black',
                      'font-dmsans font-normal'
                    )}
                    onClick={(e) => {
                      if (item.submenu) {
                        e.preventDefault();
                        handleDropdown(item.name);
                      } else {
                        goTo(item.href);
                      }
                    }}
                    aria-current={item.current ? 'page' : undefined}
                  >
                    {item.name}
                  </a>
                  {item.submenu && dropdownOpen === item.name && (
                    <div className="absolute mt-2 w-48 rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5">
                      <div className="py-1">
                        {item.submenu.map((subitem) => (
                          <a
                            key={subitem.name}
                            href="#"
                            className="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-200"
                            onClick={() => {
                              goTo(subitem.href);
                            }}
                          >
                            {subitem.name}
                          </a>
                        ))}
                      </div>
                    </div>
                  )}
                </div>
              ))}
            </nav>
          </div>
        </div>
        <div>
          <ul className="flex items-center space-x-1 md:space-x-5">
            <li className="flex items-center sm:hidden">
              <button
                type="button"
                className={classNames(
                  homepage
                    ? 'bg-white text-heading'
                    : 'bg-[#222222] text-white',
                  'flex justify-center items-center w-[46px] h-[46px] rounded-full focus:outline-none focus:ring-2 focus:ring-white focus:ring-offset-2 focus:ring-offset-gray-800'
                )}
                aria-controls="mobile-menu"
                aria-expanded="false"
                onClick={() => setOpenMenu(true)}
              >
                <span className="sr-only">Open main menu</span>
                <IconHamburger
                  className={classNames(
                    homepage ? 'text-[#222222]' : 'text-white',
                    'h-[22px]'
                  )}
                />
              </button>
            </li>
            {signed ? (
              <>
                <li className="relative">
                  <DropdownMenu.Root>
                    <DropdownMenu.DropdownMenuTrigger asChild>
                      <button
                        type="button"
                        className="flex justify-center items-center w-[46px] h-[46px] rounded-full text-white focus:outline-none focus:ring-2 focus:ring-white focus:ring-offset-2 focus:ring-offset-gray-800"
                        aria-expanded="false"
                        aria-haspopup="true"
                      >
                        <span className="sr-only">Notificações</span>
                        <Bell
                          color={homepage ? colors.white : colors.graydark}
                          size={Sizes.medium}
                        />
                      </button>
                    </DropdownMenu.DropdownMenuTrigger>
                  </DropdownMenu.Root>
                </li>
                <li className="relative hidden sm:block">
                  <Dropdown
                    trigger={
                      <button
                        type="button"
                        className="flex justify-center items-center w-[46px] h-[46px] rounded-full bg-gray-700 text-white focus:outline-none focus:ring-2 focus:ring-white focus:ring-offset-2 focus:ring-offset-gray-800"
                        aria-expanded="false"
                        aria-haspopup="true"
                      >
                        <span className="sr-only">Perfil</span>
                        <span className="leading-4 font-bold">
                          {getLettersName(user.name)}
                        </span>
                        <span className="flex h-4 items-end">
                          <CaretDown color={colors.white} size={Sizes.nano} />
                        </span>
                      </button>
                    }
                  >
                    <div
                      className="absolute -right-5 z-10 mt-2 w-48 origin-top-right rounded-md bg-white py-1 shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none"
                      role="menu"
                      aria-orientation="vertical"
                      aria-labelledby="user-menu-button"
                      tabIndex={-1}
                    >
                      {' '}
                      <a
                        href="#"
                        className="flex items-center px-4 py-2 text-sm text-gray-700"
                        role="menuitem"
                        tabIndex={-1}
                        onClick={() => goTo('/profile')}
                      >
                        <MdPerson className="mr-2" />
                        Meu Perfil
                      </a>
                      <a
                        href="#"
                        className="flex items-center px-4 py-2 text-sm text-gray-700"
                        role="menuitem"
                        tabIndex={-1}
                        onClick={() => goTo('/pedidos')}
                      >
                        <MdAssignmentInd className="mr-2" />
                        Meus Pedidos
                      </a>
                      {user && role && role.toString() === 'TEACHER' ? (
                        <>
                          <a
                            href="#"
                            tabIndex={-1}
                            onClick={() => goTo('/alunos')}
                            className="flex items-center px-4 py-2 text-sm text-gray-700"
                          >
                            <MdPerson className="mr-2" /> Alunos
                          </a>
                          <a
                            href="#"
                            tabIndex={-1}
                            onClick={() => goTo('/treinos')}
                            className="flex items-center px-4 py-2 text-sm text-gray-700"
                          >
                            <MdAccessibilityNew className="mr-2" /> Treinos
                          </a>
                          <a
                            href="#"
                            className="flex items-center px-4 py-2 text-sm text-gray-700"
                            role="menuitem"
                            tabIndex={-1}
                            onClick={() => goTo('/inscricoes')}
                          >
                            <MdAssignment className="mr-2" />
                            Inscrições
                          </a>
                          <a
                            href="#"
                            className="flex items-center px-4 py-2 text-sm text-gray-700"
                            role="menuitem"
                            tabIndex={-1}
                            onClick={() => goTo('/eventos-create')}
                          >
                            <MdEvent className="mr-2" />
                            Criar Evento
                          </a>
                          <a
                            href="#"
                            className="flex items-center px-4 py-2 text-sm text-gray-700"
                            role="menuitem"
                            tabIndex={-1}
                            onClick={() => goTo('/criar-pix')}
                          >
                            <MdAddCard className="mr-2" />
                            Criar Pix
                          </a>
                        </>
                      ) : (
                        <></>
                      )}
                      <hr className="my-2" />
                      <a
                        href="#"
                        className="flex items-center px-4 pt-2 pb-3  text-sm text-gray-700"
                        role="menuitem"
                        tabIndex={-1}
                        onClick={handleOnLogout}
                      >
                        <img src={Logout} alt="Sair" />
                        <span className="ml-3 text-sm font-normal font-dmsans">
                          Sair
                        </span>
                      </a>
                    </div>
                  </Dropdown>
                </li>
              </>
            ) : (
              <>
                <li className="hidden md:block">
                  <a
                    href="#"
                    className={classNames(
                      homepage ? 'text-white' : ' text-neutro-b-400',
                      'font-dmsans font-normal'
                    )}
                    onClick={() => goTo('/criar-conta')}
                  >
                    Criar conta
                  </a>
                </li>
                <li className="hidden md:block">
                  <button
                    id="btnDoLogin"
                    className={classNames(
                      'px-10 py-2 transition-colors duration-150 rounded-lg focus:shadow-outline bg-neutro-b-400 hover:bg-neutro-b-600 active:bg-neutro-b-400 text-base font-bold font-dmsans text-white'
                    )}
                    onClick={handleOnSignIn}
                  >
                    Entrar
                  </button>
                </li>
              </>
            )}
            {/* <li className="hidden md:block">
              <a
                href="#"
                className={classNames(
                  homepage ? 'text-white' : ' text-neutro-b-400',
                  'font-dmsans font-normal'
                )}
                onClick={() => goTo('/criar-conta')}
              >
                Criar conta
              </a>
            </li> */}
          </ul>
        </div>
        <Menu
          signed={signed}
          user={user}
          visible={openMenu}
          handleOnLogout={handleOnLogout}
          handleOnSignIn={handleOnSignIn}
          onSetVisible={setOpenMenu}
          goTo={goTo}
        />
      </div>
    </header>
  );
};
