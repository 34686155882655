import React from 'react';
import { Switch } from 'react-router-dom';
import { CreateAccountnavigation } from '../features/createAccount/navigation';
import { renderRoutes } from './utils';
import { CheckoutNavigation } from 'src/features/checkout/navigation';
import { HomeNavigation } from '../features/home/navigation';
import { EventNavigation } from 'src/features/event/screens/navigation';
import { CreatePixNavigation } from 'src/features/pix/screens/navigation';
import { TicketsNavigation } from 'src/features/tickets/navigation';
import { TrainingNavigation } from 'src/features/training/navigation';
import { ProfileNavigation } from 'src/features/profile/navigation';

// import { Route } from './Route';
// import { path } from './path';

const Navigation: React.FC = (): JSX.Element => {
  const homeRoutes = renderRoutes(HomeNavigation);
  const createAccountRoutes = renderRoutes(CreateAccountnavigation);
  const eventRoutes = renderRoutes(EventNavigation);
  const checkoutRoutes = renderRoutes(CheckoutNavigation);
  const createPixRoutes = renderRoutes(CreatePixNavigation);
  const ticketsRoutes = renderRoutes(TicketsNavigation);
  const trainingRoutes = renderRoutes(TrainingNavigation);
  const profile = renderRoutes(ProfileNavigation);

  return (
    <Switch>
      {homeRoutes}
      {createAccountRoutes}
      {eventRoutes}
      {checkoutRoutes}
      {createPixRoutes}
      {ticketsRoutes}
      {trainingRoutes}
      {profile}
    </Switch>
  );
};

export { Navigation };
