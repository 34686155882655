/* eslint-disable @typescript-eslint/no-explicit-any */
import { IconTicket } from 'src/assets/icons/IconTicket';
import { TableColumn } from 'src/components/Table';
import { AuthWrapper } from 'src/features/core/auth/screens/AuthWrapper';
import {
  FormInputNameFilter,
  ShowModalTickets,
  UseModalControl,
} from 'src/features/tickets/types';
// import { ConfirmCancelTicketAfterContent } from 'src/features/tickets/components/ConfirmCancelTicketAfterContent';
import { UseFormControl } from 'src/types';
import { Order } from 'src/model/Order';
import Page from 'src/model/Page';
import PaymentStatus from 'src/model/PaymentStatus';
import dayjs from 'dayjs';
import * as Dialog from '@radix-ui/react-dialog';
import React, { useState } from 'react';
import { Close } from 'src/assets/icons';
import * as RadioGroupPrimitive from '@radix-ui/react-radio-group';
import classNames from 'classnames';
import OrderEvent from 'src/model/OrderEvent';
// import TicketCard from 'src/features/tickets/components/CardTicket';
import { OrderResponse } from 'src/model/OrderResponse';
import TicketCard from '../components/CardTicket';

interface ElementStateProps {
  order: Order;
}

interface StatusProps {
  state: boolean;
  orders: OrderResponse[];
  order: Order;
  controllerModalTicket: UseModalControl;
  //   controllerFilter: UseFormControl;
}

interface DispatchProps {
  //   onShowCancelTicket: (item: Order) => void;
  //   onSubmitCancelTicket: () => void;
  onShowDetailTicket: (item: string) => void;
  //   onShowPrintTicket: (item: Order) => void;
  //   onShowTransferTicket: (item: Order) => void;
  //   onSubmitFilter: () => void;
  //   onClearFilter: () => void;
  //   onPressTutotialApp: () => void;
}

type TicketsOrderProps = StatusProps & DispatchProps;

export interface DataRow {
  id: string;
  imageBase64: string;
  name: string;
  buyDate: string;
  startDate: string;
  statusPayment: string;
  price: number;
  status: string;
  actions: string;
}

export const columns: TableColumn<DataRow>[] = [
  {
    name: '',
    selector: (row: { imageBase64: any }) => row.imageBase64,
    width: '100px',
  },
  {
    name: 'Nome do evento',
    selector: (row: { name: any }) => row.name,
    width: '200px',
  },
  {
    name: 'Data da compra',
    selector: (row: { buyDate: any }) => row.buyDate,
  },
  {
    name: 'Data do evento',
    selector: (row: { startDate: any }) => row.startDate,
  },
  {
    name: 'Pagamento',
    selector: (row: { statusPayment: any }) => row.statusPayment,
    width: '120px',
  },
  {
    name: 'Valor',
    selector: (row: { price: any }) => row.price,
    width: '140px',
  },
  {
    name: '',
    selector: (row: { status: any }) => row.status,
    width: '130px',
  },
  {
    name: '',
    selector: (row: { actions: any }) => row.actions,
    width: '60px',
  },
];

export const TicketsOrder = ({
  state,
  orders,
  order,
  controllerModalTicket,
  onShowDetailTicket,
}: //   onSubmitCancelTicket,
//   controllerFilter,
//   onSubmitFilter,
//   onClearFilter,
//   onPressTutotialApp,
TicketsOrderProps) => {
  const getPaymentStatus = (status: PaymentStatus): string => {
    let s = '';
    switch (status) {
      case PaymentStatus.APPROVED:
        s = 'Aprovado';
        break;
      case PaymentStatus.DISAPPROVED:
        s = 'Reprovado';
        break;
      case PaymentStatus.AWAITING_PAYMENT:
        s = 'Aguardando Pagamento';
        break;
      case PaymentStatus.CANCELED:
        s = 'Cancelado';
        break;
      case PaymentStatus.CANCELLATION_ANALYSIS:
        s = 'Solicitação de cancelamento em analise';
        break;
      case PaymentStatus.EXPIRED:
        s = 'Expirado';
        break;
      default:
        s = '';
        break;
    }
    return s;
  };
  const toCurrency = (value: number): string => {
    const formatter = new Intl.NumberFormat('pt-BR', {
      style: 'currency',
      currency: 'BRL',
    });
    return formatter.format(value);
  };
  const getTicketStatus = (order: Order): string => {
    let s = 'Disponível';
    const paymentStatusEnum =
      PaymentStatus[
        order.paymentStatus as unknown as keyof typeof PaymentStatus
      ];

    if (paymentStatusEnum === PaymentStatus.CANCELED) {
      s = 'Cancelado';
    } else if (paymentStatusEnum === PaymentStatus.DISAPPROVED) {
      s = 'Reprovado';
    } else if (paymentStatusEnum === PaymentStatus.EXPIRED) {
      s = 'Expirado';
    } else if (paymentStatusEnum === PaymentStatus.AWAITING_PAYMENT) {
      s = 'Aguardando Pagamento';
    } else if (paymentStatusEnum === PaymentStatus.APPROVED) {
      s = 'Aprovado';
    }

    console.log('Resultado:', s);
    return s;
  };

  const ElementStatus: React.FC<ElementStateProps> = ({
    order,
  }): JSX.Element => {
    const test = getTicketStatus(order);
    let classText = 'order-table-tbody-td-child ';

    if (test === 'Disponível') {
      classText += 'bg-status-nao-utilizado ml-2';
    } else if (test === 'Cancelado') {
      classText += 'bg-status-cancelado ml-2';
    } else if (test === 'Transferido') {
      classText += 'bg-status-transferido ml-2';
    } else if (test === 'Aguardando Pagamento') {
      classText += 'bg-status-await ml-2';
    }
    return (
      <div className="order-table-tbody-td-child flex text-center align-middle">
        <span className="font-bold">Status:</span>
        <div className={classText}> {test}</div>
      </div>
    );
  };

  //   const mountEventName = (events: OrderEvent[]): string | JSX.Element => {
  //     if (events.length === 1) {
  //       return events[0].event.name;
  //     } else {
  //       return (
  //         <>
  //           {events.map((data, index) => (
  //             <>
  //               <span key={`eventNameSpan-${data.event.id}`}>
  //                 {data.event.name}
  //               </span>
  //               {index < events.length - 1 && <br />}
  //             </>
  //           ))}
  //         </>
  //       );
  //     }
  //   };

  const dataTicket = orders
    ? orders.map((item) => {
        return {
          id: item.event.id,
          thumbnail: item.event.image,
          name: item.event.name,
          startDate: dayjs(item.event.startDate)
            .locale('pt-br')
            .format('DD/MM/YYYY'),
          endDate: dayjs(item.event.endDate)
            .locale('pt-br')
            .format('DD/MM/YYYY'),
          status: <ElementStatus order={item.order} />,
          statusPayment: (
            <span title={getPaymentStatus(item.order.paymentStatus)}>
              {getPaymentStatus(item.order.paymentStatus)}
            </span>
          ),
          option: (
            <React.Fragment>
              <button
                className="text-18 text-center text-gray-600 bg-gray-400 hover:bg-gray-700 text-white font-bold py-2 px-3 mr-2 rounded cursor-pointer"
                onClick={() => onShowDetailTicket(item.order.id)}
              >
                Ver Detalhes
              </button>
            </React.Fragment>
          ),
        };
      })
    : [];

  //   const [openFilter, setOpenFilter] = useState(false);

  //   const [value, setValue] = useState<string>('Data');

  //   const radioFilters = [
  //     { id: 'date', title: 'Data da compra' },
  //     { id: 'name', title: 'Nome do evento' },
  //   ];

  return (
    <AuthWrapper isHomepage={false}>
      <div className="w-full p-7 md:px-0 container mx-auto py-24">
        <header className="flex flex-wrap items-center mb-[70px]">
          <div className="w-full flex justify-between items-center">
            <div className="flex items-center">
              <IconTicket className="mr-[20px] h-[30px]" />
              <h1 className="font-dmsans font-medium text-[37px]">
                Meus Pedidos
              </h1>
            </div>
          </div>
          <p className="w-full ml-[25px] items-center text-[18px] font-dmsans font-normal text-blue-600 cursor-pointer">
            - Visualize suas incrições.
          </p>
        </header>
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 gap-2">
          {dataTicket.map((item, index) => (
            <TicketCard key={index} ticketData={item} />
          ))}
        </div>
        {/* <div className="font-dmsans">
          <CustomTable
            columns={columns}
            data={dataTable}
            numberRowsPerPage={2}
            progressPending={state}
            pagination
          />
        </div> */}
      </div>
    </AuthWrapper>
  );
};
