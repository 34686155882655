import React, { FormEvent } from 'react';
import { CalendarGrey, IconEye, IconEyeClose } from 'src/assets/icons';
import { Button } from 'src/components/Button';
import { Checkbox } from 'src/components/Checkbox';
import { countryOptions } from 'src/components/Country';
import { InputFile } from 'src/components/InputFile';
import { InputText } from 'src/components/InputText';
import { Select } from 'src/components/Select';
import {
  FormInputNameRegister,
  ShouldShowModal,
  ShouldShowModalProps,
} from 'src/features/core/auth/types';
import { classNames } from 'src/helpers/common';
import { UseFormReturn } from 'src/hooks';

export interface NameFiles {
  [key: string]: string;
}

export interface CreateAccountUIProps
  extends Pick<UseFormReturn, 'formData' | 'formErrors' | 'onChangeFormInput'> {
  state: boolean;
  shouldShowPasswordToText: boolean;
  shouldShowPasswordToText2: boolean;
  formNameFiles?: NameFiles;
  mestres: OptionProps[];
  onChangeCEP: (value: string) => void;
  onTogglePasswordToText: () => void;
  onTogglePasswordToText2: () => void;
  onShouldShowModal: ({ value, title }: ShouldShowModalProps) => void;
  onSubmitRegister: (e: FormEvent) => void;
  onChangeFormFileInput: (
    inputName: string
  ) => (file: File | undefined) => void;
}
export interface OptionProps {
  value?: string | number;
  label: string;
  target?: HTMLInputElement;
}

export const CreateAccountUi: React.FC<CreateAccountUIProps> = ({
  state,
  formData,
  formErrors,
  formNameFiles,
  mestres,
  onChangeFormInput,
  shouldShowPasswordToText,
  shouldShowPasswordToText2,
  onTogglePasswordToText,
  onTogglePasswordToText2,
  onShouldShowModal,
  onSubmitRegister,
  onChangeFormFileInput,
  onChangeCEP,
}) => {
  const imageBase64Ref = React.useRef<HTMLInputElement>(null);

  const genderOption: OptionProps[] = [
    { value: 'MALE', label: 'Masculino' },
    { value: 'FAMELE', label: 'Feminino' },
    { value: 'OTHER', label: 'Não Declarar' },
  ];

  const corda: OptionProps[] = [
    { value: 'SEM_CORDA', label: 'Sem corda' },
    { value: 'AZUL', label: 'Azul' },
    { value: 'AZUL_MARROM', label: 'Azul-Marrom' },
    { value: 'MARROM', label: 'Marrom' },
    { value: 'MARROM_VERDE', label: 'Marrom-Verde' },
    { value: 'VERDE', label: 'Verde' },
    { value: 'VERDE_AMARELA', label: 'Verde_Amarela' },
    { value: 'AMARELA', label: 'Amarela' },
    { value: 'AMARELA_ROXA', label: 'Amarela-Roxa' },
    { value: 'ROXA', label: 'Roxa' },
    { value: 'VERMELHA', label: 'Vermelha' },
    { value: 'BRANCA', label: 'Branca' },
  ];

  const stateOptions: OptionProps[] = [
    { value: 'AC', label: 'Acre' },
    { value: 'AL', label: 'Alagoas' },
    { value: 'AP', label: 'Amapa' },
    { value: 'AM', label: 'Amazonas' },
    { value: 'BA', label: 'Bahia' },
    { value: 'CE', label: 'Ceara' },
    { value: 'DF', label: 'Distrito Federal' },
    { value: 'ES', label: 'Espirito Santo' },
    { value: 'GO', label: 'Goias' },
    { value: 'MA', label: 'Maranhao' },
    { value: 'MT', label: 'Mato Grosso' },
    { value: 'MS', label: 'Mato Grosso do Sul' },
    { value: 'MG', label: 'Minas Gerais' },
    { value: 'PA', label: 'Para' },
    { value: 'PB', label: 'Paraiba' },
    { value: 'PR', label: 'Parana' },
    { value: 'PE', label: 'Pernambuco' },
    { value: 'PI', label: 'Piaui' },
    { value: 'RJ', label: 'Rio de Janeiro' },
    { value: 'RN', label: 'Rio Grande do Norte' },
    { value: 'RS', label: 'Rio Grande do Sul' },
    { value: 'RO', label: 'Rondonia' },
    { value: 'RR', label: 'Roraima' },
    { value: 'SC', label: 'Santa Catarina' },
    { value: 'SP', label: 'Sao Paulo' },
    { value: 'SE', label: 'Sergipe' },
    { value: 'TO', label: 'Tocantins' },
  ];
  const graduationOptions: OptionProps[] = [
    { value: 'ALUNO', label: 'Aluno' },
    { value: 'MONITOR', label: 'Monitor' },
    { value: 'IMPROVER', label: 'Estagiario' },
    { value: 'INSTRUCTOR', label: 'Instrutor' },
    { value: 'CONTRAMESTRE', label: 'Contramestre' },
    { value: 'MESTRE_EDIFICADOR', label: 'Mestre Edificador' },
    { value: 'MESTRE_DIGNIFICADOR', label: 'Mestre Dignificador' },
    { value: 'OTHER', label: 'Outra' },
  ];

  return (
    <section className="p-4 space-y-[70px] mt-[15px] mb-[100px] max-w-5xl mx-auto ">
      <form onSubmit={onSubmitRegister}>
        <div className="mt-5 pt-5 max-w-5xl mx-auto border border-gray-100 rounded shadow-outline pb-6">
          <div className="ml-5">
            <h1 className="text-gray-700 text-2xl font-dmsans font-bold mb-10">
              Cadastro de Membro
            </h1>
          </div>
          <div className="p-3 max-w-4xl mx-auto">
            <div>
              <Select
                name="mestre"
                label="Com quem você treina"
                placeholder="Selecione..."
                className="w-full mb-5"
                value={formData[FormInputNameRegister.mestre]}
                onChange={(e) =>
                  onChangeFormInput(FormInputNameRegister.mestre)(
                    e?.target.value as string
                  )
                }
                error={formErrors.mestre && formErrors.mestre[0]}
                options={mestres}
              />
              <InputText
                name="name"
                label="Nome Completo"
                placeholder="Digite aqui..."
                maxLength={100}
                value={formData[FormInputNameRegister.name]}
                onChange={(e) =>
                  onChangeFormInput(FormInputNameRegister.name)(e.target.value)
                }
                error={formErrors.name && formErrors.name[0]}
              />
              <InputText
                name="nickname"
                label="Apelido"
                placeholder="Digite aqui..."
                maxLength={100}
                value={formData[FormInputNameRegister.nickname]}
                onChange={(e) =>
                  onChangeFormInput(FormInputNameRegister.nickname)(
                    e.target.value
                  )
                }
                error={formErrors.nickname && formErrors.nickname[0]}
              />

              <div className="mb-5 mt-5 grid grid-cols-1 md:grid-cols-2 gap-4">
                <InputText
                  name="document"
                  label="CPF"
                  placeholder="Digite aqui..."
                  maxLength={100}
                  value={formData[FormInputNameRegister.document]}
                  onChange={(e) =>
                    onChangeFormInput(FormInputNameRegister.document)(
                      e.target.value
                    )
                  }
                  error={formErrors.document && formErrors.document[0]}
                />
                <Select
                  name="gender"
                  label="Sexo:"
                  placeholder="Selecione..."
                  className="w-full"
                  value={formData[FormInputNameRegister.gender]}
                  onChange={(e) =>
                    onChangeFormInput(FormInputNameRegister.gender)(
                      e?.target.value as string
                    )
                  }
                  error={formErrors.gender && formErrors.gender[0]}
                  options={genderOption}
                />
              </div>
              <div className="mb-5 mt-5 grid grid-cols-1 md:grid-cols-2 gap-4">
                <InputText
                  name="date"
                  label="Data de nascimento"
                  placeholder="DD/MM/AAAA"
                  className={`appearance-none w-full
                rounded-md text-heading leading-tight focus:outline-none
                focus:shadow-outline text-xs font-dmsans font-normal`}
                  value={formData[FormInputNameRegister.date]}
                  onChange={(e) =>
                    onChangeFormInput(FormInputNameRegister.date)(
                      e.target.value
                    )
                  }
                  error={formErrors.date && formErrors.date[0]}
                  renderForward={
                    <button
                      className={classNames(
                        formErrors.date && formErrors.date[0]
                          ? 'right-0 bottom-7'
                          : 'right-0 bottom-3',
                        'absolute cursor-pointer mr-4'
                      )}
                      type="button"
                    >
                      <div className="h-8 flex flex-col justify-center items-center">
                        <img src={CalendarGrey} style={{ cursor: 'none' }} />
                      </div>
                    </button>
                  }
                />
                <InputText
                  name="phone"
                  typeof="number"
                  label="Telefone"
                  placeholder="Digite aqui..."
                  maxLength={100}
                  value={formData[FormInputNameRegister.phone]}
                  onChange={(e) =>
                    onChangeFormInput(FormInputNameRegister.phone)(
                      e?.target.value as string
                    )
                  }
                  error={formErrors.phone && formErrors.phone[0]}
                />
              </div>
              <div className="mb-5 mt-5 grid grid-cols-1 md:grid-cols-2 gap-4">
                <Select
                  name="graduation"
                  label="Graduação"
                  placeholder="Selecione..."
                  maxLength={100}
                  value={formData[FormInputNameRegister.graduation]}
                  options={graduationOptions}
                  onChange={(e) =>
                    onChangeFormInput(FormInputNameRegister.graduation)(
                      e?.target.value as string
                    )
                  }
                  error={formErrors.graduation && formErrors.graduation[0]}
                />
                <Select
                  name="corda"
                  label="Qual a sua Corda"
                  placeholder="Selecione..."
                  maxLength={100}
                  value={formData[FormInputNameRegister.corda]}
                  options={corda}
                  onChange={(e) =>
                    onChangeFormInput(FormInputNameRegister.corda)(
                      e?.target.value as string
                    )
                  }
                  error={formErrors.corda && formErrors.corda[0]}
                />
                <InputText
                  name="email"
                  label="E-mail"
                  placeholder="Digite aqui..."
                  maxLength={100}
                  value={formData[FormInputNameRegister.email]}
                  onChange={(e) =>
                    onChangeFormInput(FormInputNameRegister.email)(
                      e?.target.value as string
                    )
                  }
                  error={formErrors.email && formErrors.email[0]}
                />
              </div>
              <div className="mb-5 mt-5 grid grid-cols-1 md:grid-cols-2 gap-4">
                <InputFile
                  refInput={imageBase64Ref}
                  name="imageBase64"
                  label="Foto"
                  fileName={formNameFiles?.imageBase64}
                  onChange={(e) =>
                    onChangeFormFileInput(FormInputNameRegister.imageBase64)(
                      (e.target as HTMLInputElement)?.files?.[0]
                    )
                  }
                  error={formErrors.imageBase64 && formErrors.imageBase64[0]}
                />
              </div>
            </div>
            <div>
              <h1 className="mt-10 mb-2 text-gray-700 text-lg font-dmsans font-bold">
                Endereço:
              </h1>

              <div className="mb-5 grid grid-cols-1 md:grid-cols-2 gap-4">
                <InputText
                  name="zipCode"
                  label="CEP"
                  placeholder="00000-000"
                  className={`appearance-none w-full
                rounded-md text-heading leading-tight focus:outline-none
                focus:shadow-outline text-xs font-dmsans font-normal`}
                  value={formData[FormInputNameRegister.zipCode]}
                  maxLength={9}
                  onChange={(e) => {
                    onChangeFormInput(FormInputNameRegister.zipCode)(
                      e.target.value
                    );
                    onChangeCEP(e.target.value);
                  }}
                  error={formErrors.zipCode && formErrors.zipCode[0]}
                />
                <InputText
                  name="street"
                  label="Rua/Avenida/Conjunto"
                  placeholder="Digite aqui..."
                  maxLength={100}
                  value={formData[FormInputNameRegister.street]}
                  onChange={(e) =>
                    onChangeFormInput(FormInputNameRegister.street)(
                      e?.target.value as string
                    )
                  }
                  error={formErrors.street && formErrors.street[0]}
                />
              </div>
              <div className="mb-5 grid grid-cols-1 md:grid-cols-3 gap-4">
                <InputText
                  name="addressNumber"
                  label="Número"
                  placeholder="Digite aqui..."
                  maxLength={100}
                  value={formData[FormInputNameRegister.addressnumber]}
                  onChange={(e) =>
                    onChangeFormInput(FormInputNameRegister.addressnumber)(
                      e?.target.value as string
                    )
                  }
                  error={
                    formErrors.addressnumber && formErrors.addressnumber[0]
                  }
                />
                <InputText
                  name="complement"
                  label="Complemento (opcional)"
                  placeholder="Digite aqui..."
                  maxLength={100}
                  value={formData[FormInputNameRegister.complement]}
                  onChange={(e) =>
                    onChangeFormInput(FormInputNameRegister.complement)(
                      e?.target.value as string
                    )
                  }
                  error={formErrors.complement && formErrors.complement[0]}
                />
                <InputText
                  name="district"
                  label="Bairro:"
                  placeholder="Digite aqui..."
                  maxLength={100}
                  value={formData[FormInputNameRegister.district]}
                  onChange={(e) =>
                    onChangeFormInput(FormInputNameRegister.district)(
                      e?.target.value as string
                    )
                  }
                  error={formErrors.district && formErrors.district[0]}
                />
                <InputText
                  name="city"
                  label="Cidade:"
                  placeholder="Digite aqui..."
                  maxLength={100}
                  value={formData[FormInputNameRegister.city]}
                  onChange={(e) =>
                    onChangeFormInput(FormInputNameRegister.city)(
                      e?.target.value as string
                    )
                  }
                  error={formErrors.city && formErrors.city[0]}
                />
                <Select
                  name="state"
                  label="Estado"
                  placeholder="Selecione..."
                  value={formData[FormInputNameRegister.state]}
                  onChange={(e) =>
                    onChangeFormInput(FormInputNameRegister.state)(
                      e?.target.value as string
                    )
                  }
                  className="col-span-2"
                  error={formErrors.state && formErrors.state[0]}
                  options={stateOptions}
                ></Select>
                <Select
                  name="country"
                  label="Pais"
                  placeholder="Selecione..."
                  value={formData[FormInputNameRegister.country]}
                  onChange={(e) =>
                    onChangeFormInput(FormInputNameRegister.country)(
                      e?.target.value as string
                    )
                  }
                  className="col-span-2"
                  error={formErrors.country && formErrors.country[0]}
                  options={countryOptions}
                ></Select>
              </div>
            </div>
          </div>
          <div>
            <div className="max-w-4xl mx-auto p-3">
              <h1 className="mt-10 mb-2 text-gray-700 text-lg font-dmsans font-bold">
                Acesso:
              </h1>
              <div className="mb-5 grid grid-cols-1 md:grid-cols-1 gap-4">
                <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
                  <div>
                    <InputText
                      className="mb-3"
                      name="password"
                      label="Senha"
                      type={shouldShowPasswordToText ? 'text' : 'password'}
                      placeholder="**********"
                      value={formData[FormInputNameRegister.password]}
                      maxLength={15}
                      onChange={(e) =>
                        onChangeFormInput(FormInputNameRegister.password)(
                          e.target.value
                        )
                      }
                      error={formErrors.password && formErrors.password[0]}
                      renderForward={
                        <button
                          className={classNames(
                            formErrors.password && formErrors.password[0]
                              ? 'right-0 bottom-7'
                              : 'right-0 bottom-3',
                            'absolute cursor-pointer mr-4'
                          )}
                          onClick={onTogglePasswordToText}
                          type="button"
                        >
                          <div className="h-8 flex flex-col justify-center items-center">
                            {shouldShowPasswordToText ? (
                              <img src={IconEye} alt="Mostrar senha" />
                            ) : (
                              <img src={IconEyeClose} alt="Ocultar senha" />
                            )}
                          </div>
                        </button>
                      }
                    />
                    <InputText
                      name="confirmPassword"
                      label="Confirme a sua senha"
                      type={shouldShowPasswordToText2 ? 'text' : 'password'}
                      placeholder="**********"
                      value={formData[FormInputNameRegister.confirmPassword]}
                      maxLength={15}
                      onChange={(e) =>
                        onChangeFormInput(
                          FormInputNameRegister.confirmPassword
                        )(e.target.value)
                      }
                      error={
                        formErrors.confirmPassword &&
                        formErrors.confirmPassword[0]
                      }
                      renderForward={
                        <button
                          className={classNames(
                            formErrors.confirmPassword &&
                              formErrors.confirmPassword[0]
                              ? 'right-0 bottom-7'
                              : 'right-0 bottom-3',
                            'absolute cursor-pointer mr-4'
                          )}
                          onClick={onTogglePasswordToText2}
                          type="button"
                        >
                          <div className="h-8 flex flex-col justify-center items-center">
                            {shouldShowPasswordToText2 ? (
                              <img src={IconEye} alt="Mostrar senha" />
                            ) : (
                              <img src={IconEyeClose} alt="Ocultar senha" />
                            )}
                          </div>
                        </button>
                      }
                    />
                  </div>
                  <div className="mb-[20px] text-neutro-w-600 text-base font-dmsans font-normal">
                    <p className="mb-5">Sua senha deve conter:</p>
                    <ul className="text-neutro-w-500 list-disc list-item ml-6">
                      <li>No mínimo 8 caracteres;</li>
                      <li>Pelo menos um número;</li>
                      <li>Pelo menos um caractere maiúsculo.</li>
                    </ul>
                  </div>
                </div>

                <Checkbox
                  name="terms"
                  value={formData[FormInputNameRegister.terms]}
                  className="mb-[20px]"
                  onChange={(checked) =>
                    onChangeFormInput(FormInputNameRegister.terms)(checked)
                  }
                  error={formErrors.terms && formErrors.terms[0]}
                  renderForward={
                    <div className="ml-3 text-label text-base font-dmsans font-regular mr-2">
                      Eu aceito
                      <a
                        className="underline underline-offset-2 md:inline-block
            align-baseline text-label text-base font-normal font-dmsans mx-1"
                        href="#"
                        onClick={() =>
                          onShouldShowModal({
                            value: ShouldShowModal.TERMS,
                            title: 'Termos e Política de Privacidade',
                          })
                        }
                      >
                        Termos e Política de Privacidade
                      </a>
                      do site
                    </div>
                  }
                />
              </div>
              <Button
                type="submit"
                title="Criar a minha conta"
                buttonStyle="primary"
                size="xlg"
                className="w-full"
                disabled={
                  formData[FormInputNameRegister.name] === '' ||
                  formData[FormInputNameRegister.document] === '' ||
                  formData[FormInputNameRegister.email] === '' ||
                  formData[FormInputNameRegister.phone] === '' ||
                  formData[FormInputNameRegister.date] === '' ||
                  formData[FormInputNameRegister.password] === '' ||
                  formData[FormInputNameRegister.confirmPassword] === '' ||
                  formData[FormInputNameRegister.terms] === '' ||
                  formData[FormInputNameRegister.terms] === 'false' ||
                  state
                }
              />
            </div>
          </div>
        </div>
      </form>
    </section>
  );
};
