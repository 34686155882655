import React, { FormEvent, useState } from 'react';
import { UseFormReturn } from 'src/hooks';
import { TrainingData } from 'src/model/TrainingData';
import { CreateClassUI } from '../../CreateClass/ui';

interface TrainingUIProps
  extends Pick<UseFormReturn, 'formData' | 'formErrors' | 'onChangeFormInput'> {
  state: boolean;
  onSubmitRegister: (e: FormEvent) => void;
  data: TrainingData[];
}

export const TrainingsUI: React.FC<TrainingUIProps> = ({
  data,
  state,
  formData,
  formErrors,
  onSubmitRegister,
  onChangeFormInput,
}): JSX.Element => {
  const [showModal, setShowModal] = useState(false);

  const handleTypeTraining = (e: string): string => {
    let type = 'Não definido';
    switch (e) {
      case 'CHILDREN':
        type = 'Infantil';
        break;
      case 'YOUTH':
        type = 'Jovem';
        break;
      case 'ADULT':
        type = 'Adulto';
        break;
      default:
        break;
    }

    return type;
  };

  const handleDayOfWeek = (day: string): string => {
    let dayName = 'Dia não definido';
    switch (day) {
      case 'MONDAY':
        dayName = 'Segunda-feira';
        break;
      case 'TUESDAY':
        dayName = 'Terça-feira';
        break;
      case 'WEDNESDAY':
        dayName = 'Quarta-feira';
        break;
      case 'THURSDAY':
        dayName = 'Quinta-feira';
        break;
      case 'FRIDAY':
        dayName = 'Sexta-feira';
        break;
      case 'SATURDAY':
        dayName = 'Sábado';
        break;
      case 'SUNDAY':
        dayName = 'Domingo';
        break;
      default:
        break;
    }

    return dayName;
  };

  return (
    <section className="w-full">
      <div className="container w-full md:max-w-[1110px] mx-auto flex flex-col items-center">
        <div className="p-8 md:flex-shrink-0">
          <div className="text-[#013209] font-extrabold text-3xl sm:text-4xl lg:text-5xl tracking-tight text-center dark:text-white mb-7">
            Treinos
          </div>

          <div className="p-4 space-y-6">
            {data.map((item, index) => (
              <div key={index} className="bg-white shadow-md rounded-lg p-6">
                <h2 className="text-2xl font-bold mb-2">
                  <span className="text-xl">Instrutor:</span>{' '}
                  {item.training.name}
                </h2>
                <p className="text-gray-600">
                  {handleTypeTraining(item.training.type.toString())}
                </p>
                <p className="mt-4">
                  <span className="font-semibold">Endereço:</span>{' '}
                  {item.training.address ? item.training.address.street : ''},{' '}
                  {item.training.address ? item.training.address.number : ''} -{' '}
                  {item.training.address ? item.training.address.city : ''},{' '}
                  {item.training.address ? item.training.address.state : ''},{' '}
                  {item.training.address ? item.training.address.country : ''})
                </p>
                <div className="mt-4 space-y-2">
                  <h3 className="text-lg font-semibold">Aulas:</h3>
                  {item.classTraining.map((classItem, idx) => (
                    <div
                      key={idx}
                      className="flex justify-between items-center bg-gray-100 p-3 rounded-md"
                    >
                      <p className="text-gray-700">
                        {handleDayOfWeek(classItem.day)} - {classItem.hour}
                      </p>
                      <p className="text-gray-500">
                        {classItem.status === 'ACTIVE' ? 'Treino Ativo' : ''}
                      </p>
                    </div>
                  ))}
                </div>
              </div>
            ))}
            <button
              className="bg-gray-500 text-white font-bold py-2 px-4 rounded mb-6"
              onClick={() => setShowModal(true)}
            >
              Criar Nova Aula
            </button>
          </div>
        </div>
      </div>

      {/* Modal */}
      {showModal ? (
        <div className="fixed z-10 inset-0 overflow-y-auto">
          <div className="flex items-center justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
            <div className="fixed inset-0 transition-opacity">
              <div className="absolute inset-0 bg-gray-500 opacity-75"></div>
            </div>
            <span className="hidden sm:inline-block sm:align-middle sm:h-screen"></span>
            &#8203;
            <div className="inline-block align-bottom bg-white rounded-lg text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-4xl sm:w-full">
              <div className="bg-white px-4 pt-5 pb-4 sm:p-6 sm:pb-4">
                <div className="sm:flex sm:items-start">
                  <div className="mx-auto flex-shrink-0 flex items-center justify-center h-12 w-12 rounded-full bg-green-100 sm:mx-0 sm:h-10 sm:w-10">
                    {/* Ícone de + */}
                    <svg
                      className="h-6 w-6 text-green-600"
                      fill="currentColor"
                      viewBox="0 0 20 20"
                    >
                      <path
                        fillRule="evenodd"
                        d="M10 5a1 1 0 011 1v3h3a1 1 0 110 2h-3v3a1 1 0 11-2 0v-3H5a1 1 0 110-2h3V6a1 1 0 012-1z"
                        clipRule="evenodd"
                      />
                    </svg>
                  </div>
                  <div className="mt-3 text-center sm:mt-0 sm:ml-4 sm:text-left w-full">
                    <h3 className="text-lg leading-6 font-medium text-gray-900">
                      Criar Nova Aula
                    </h3>
                    <div className="mt-4">
                      <CreateClassUI
                        state={state}
                        formData={formData}
                        formErrors={formErrors}
                        onSubmitRegister={onSubmitRegister}
                        onChangeFormInput={onChangeFormInput}
                      />
                      <p className="text-sm text-gray-500 mt-4">
                        Preencha as informações da nova aula.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
              <div className="bg-gray-50 px-4 py-3 sm:px-6 sm:flex sm:flex-row-reverse">
                <button
                  type="button"
                  className="mt-3 w-full inline-flex justify-center rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-white text-base font-medium text-gray-700 hover:bg-gray-50 focus:outline-none sm:mt-0 sm:ml-3 sm:w-auto sm:text-sm"
                  onClick={() => setShowModal(false)}
                >
                  Cancelar
                </button>
              </div>
            </div>
          </div>
        </div>
      ) : null}
    </section>
  );
};
