enum PaymentStatus {
  APPROVED,
  DISAPPROVED,
  AWAITING_PAYMENT,
  CANCELED,
  CANCELLATION_ANALYSIS,
  EXPIRED,
}

export default PaymentStatus;
