import CardClient from 'src/model/CardClient';
import { UseFormControl } from 'src/types';

export interface PerfilParam {
  id: string;
}

export enum FormInputName {
  name = 'name',
  nickname = 'nickname',
  cellPhone = 'cellPhone',
}

export enum FormInputNamePassword {
  password = 'password',
  confirmPassword = 'confirmPassword',
}

export enum FormInputNameAddress {
  id = 'id',
  zipCode = 'zipCode',
  state = 'state',
  city = 'city',
  district = 'district',
  street = 'street',
  complement = 'complement',
  number = 'number',
  country = 'country',
}

export enum FormInputNameCreditCard {
  number = 'number',
  date = 'date',
  cvv = 'cvv',
  name = 'name',
  document = 'document',
}

export interface UseModalControl {
  visible: boolean;
  title: string | React.ReactNode;
  shouldShowModal: ShowModalProfile;
  onShouldShowModal: ({ value, title }: ShouldShowModalProps) => void;
  onToggleModal: () => void;
  onSetVisible: (visible: boolean) => void;
}

export type ShouldShowModalProps = {
  value: ShowModalProfile;
  title: string | React.ReactNode;
};

export enum ShowModalProfile {
  //   ADD_CREDIT_CARD = 'ADD_CREDIT_CARD',
  //   CONFIRM_DELETE_CREDIT_CARD = 'CONFIRM_DELETE_CREDIT_CARD',
  CONFIRM_DELETE_PROFILE = 'CONFIRM_DELETE_PROFILE',
}

// export interface ControllerCreditCard {
//   list: CardClient[];
//   onShowAddCreditCard: () => void;
//   onShowDeleteCreditCard: (cardId: string) => void;
//   onSubmitDeleteCreditCard: () => void;
//   controllerAddCreditCard: UseFormControl;
// }

export interface ControllerDeleteProfile {
  onShowDeleteProfile: () => void;
  onSubmitDeleteProfile: () => void;
}
