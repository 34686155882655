import dayjs from 'dayjs';
import React from 'react';
import { IconClose } from 'src/assets/icons';
import { IconInfo } from 'src/assets/icons/IconInfo';
import { IconMenu } from 'src/assets/icons/IconMenu';
import { IconTicket } from 'src/assets/icons/IconTicket';
import { IconTransfer } from 'src/assets/icons/IconTransfer';
import { DropdownMenu } from 'src/components/DropdownMenu';
import { CustomTable, TableColumn } from 'src/components/Table';
import { Order } from 'src/model/Order';
import { OrderResponse } from 'src/model/OrderResponse';
import PaymentStatus from 'src/model/PaymentStatus';
import StatusType from 'src/model/StatusType';

interface TicketLisProps {
  state: boolean;
  orders: OrderResponse[];
  onAprovedOrder: (id: string) => void;
  onInativeOrder: (id: string) => void;
  onCancelOrder: (id: string) => void;
}

interface ElementStateProps {
  order: Order;
}

export interface DataRow {
  id: string;
  name: string;
  userName: string;
  buyDate: string;
  statusPayment: string;
  mestre: string;
  actions: string;
}

const columns: TableColumn<DataRow>[] = [
  {
    name: 'Nome do evento',
    selector: (row) => row.name,
    width: '200px',
  },
  {
    name: 'Usuário',
    selector: (row) => row.userName,
    width: '150px',
  },
  {
    name: 'Data da compra',
    selector: (row) => row.buyDate,
    width: '120px',
  },
  {
    name: 'Pagamento',
    selector: (row) => row.statusPayment,
    width: '200px',
  },
  {
    name: 'Mestre',
    selector: (row) => row.mestre,
    width: '150px',
  },
  {
    name: 'Ações',
    selector: (row) => row.actions,
    width: '150px',
  },
];

export const TicketListUI: React.FC<TicketLisProps> = ({
  orders,
  state,
  onAprovedOrder,
  onInativeOrder,
  onCancelOrder,
}): JSX.Element => {
  console.log(orders);
  const getTicketStatus = (order: Order): string => {
    let s = 'Disponível';
    const paymentStatusEnum =
      PaymentStatus[
        order.paymentStatus as unknown as keyof typeof PaymentStatus
      ];

    if (paymentStatusEnum === PaymentStatus.CANCELED) {
      s = 'Cancelado';
    } else if (paymentStatusEnum === PaymentStatus.DISAPPROVED) {
      s = 'Reprovado';
    } else if (paymentStatusEnum === PaymentStatus.EXPIRED) {
      s = 'Expirado';
    } else if (paymentStatusEnum === PaymentStatus.AWAITING_PAYMENT) {
      s = 'Aguardando Pagamento';
    } else if (paymentStatusEnum === PaymentStatus.APPROVED) {
      s = 'Aprovado';
    }

    console.log('Resultado:', s);
    return s;
  };

  const ElementStatus: React.FC<ElementStateProps> = ({
    order,
  }): JSX.Element => {
    const statusType =
      StatusType[order.status as unknown as keyof typeof StatusType];
    let classText = 'order-table-tbody-td-child ';
    let s = 'Ativo';
    if (statusType === StatusType.INACTIVE) {
      s = 'Inativo';
    } else if (statusType === StatusType.REMOVED) {
      s = 'Cancelado';
    }

    if (statusType === StatusType.ACTIVE) {
      classText += 'bg-status-nao-utilizado ml-2';
    } else if (statusType === StatusType.REMOVED) {
      classText += 'bg-status-cancelado ml-2';
    } else if (statusType === StatusType.INACTIVE) {
      classText += 'bg-status-transferido ml-2';
    }
    return (
      <div className="order-table-tbody-td-child flex text-center align-middle">
        <span className="font-bold">Status:</span>
        <div className={classText}> {s}</div>
      </div>
    );
  };

  const dataTable = orders.map((item) => ({
    id: item.order.id,
    name: item.event.name,
    userName: item.user.name,
    buyDate: dayjs(item.order.createdAt).locale('pt-br').format('DD/MM/YYYY'),
    statusPayment: (
      <span title={getTicketStatus(item.order)}>
        {getTicketStatus(item.order)}
      </span>
    ),
    // status: <ElementStatus order={item.order} />,
    mestre: item.user.nameMestre,
    actions: (
      <div>
        <DropdownMenu
          title={<IconMenu className="h-[24px]" />}
          actions={[
            // {
            //   title: 'Ver detalhe',
            //   //   onClick: () => onShowDetailTicket(item),
            //   icon: <IconInfo className="h-[19px]" />,
            // },
            {
              title: 'Aprovar',
              onClick: () => onAprovedOrder(item.order.id),
              icon: <IconTicket className="h-[19px]" />,
              //   hidden: !item.showPrint,
            },
            {
              title: 'Inativar',
              onClick: () => onInativeOrder(item.order.id),
              icon: <IconTransfer className="h-[22px]" />,
              //   hidden: !item.showTransfer,
            },
            {
              className: 'text-primary-600',
              title: 'Cancelar',
              onClick: () => onCancelOrder(item.order.id),
              icon: <IconClose className="h-[14px] text-primary-600" />,
              //   hidden: !item.showCancel,
            },
          ]}
        />
      </div>
    ),
  }));
  return (
    <section className="p-4 space-y-[70px] mt-[15px] mb-[100px] max-w-5xl mx-auto ">
      <div className="mt-5 pt-5 max-w-5xl mx-auto border border-gray-100 rounded shadow-outline pb-6">
        <div className="ml-5">
          <h1 className="text-gray-700 text-2xl font-dmsans font-bold mb-10">
            Inscrições
          </h1>
        </div>
        <div className="max-w-5xl mx-auto p-4 min-h-[400px]">
          <CustomTable
            columns={columns}
            data={dataTable}
            numberRowsPerPage={2}
            progressPending={state}
          />
        </div>
      </div>
    </section>
  );
};
