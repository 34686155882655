import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { TrainingForMestre } from 'src/model/TrainingForMestre';
import { setLoading } from 'src/redux/loading/loadingSlice';
import { api } from 'src/services/api';
import { AuthWrapper } from '../../../core/auth/screens/AuthWrapper';
import { HomeContainer } from './ui';

export const HomeScreen: React.FC = (): JSX.Element => {
  const [training, setTrainings] = useState<TrainingForMestre[]>([]);
  const dispatch = useDispatch();

  const getTraining = async (): Promise<void> => {
    dispatch(setLoading(true));
    const { data } = await api.get<TrainingForMestre[]>(
      '/training/training/mestres'
    );
    setTrainings(data);
    console.log(data);
    dispatch(setLoading(false));
  };

  useEffect(() => {
    getTraining();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return (
    <AuthWrapper isHomepage>
      <HomeContainer training={training} />
    </AuthWrapper>
  );
};
